"use strict";

// gl.extend-review.js
//
// depends on gl.common.js
//
(function glExtendReview() {
  "use strict";

  var opts, $chkFavorite, $btnFavorite, $ratingInputs, $ratingTexts, $saveAsDraftHiddenFieldId, $aiRatingChkFavorite, saveAsDraftHiddenField, AUTOSAVE_ON;
  gol.review = {
    init: function init(options) {
      //console.log("review.init() called");
      // init is called from review layer

      this.preloadFiles(options);
    },
    preloadFiles: function preloadFiles(options) {
      var reviewobj = this;
      var scripts = [];
      scripts[0] = gol.local.data.jsPath + "jqplugins/jquery.glAutoSave.js";
      scripts[1] = gol.local.data.jsPath + "jqplugins/jquery.rating.min.js";
      // loaded in details-page-min in build.xml.old
      // scripts[2] = gol.local.data.jsPath + "jqplugins/jquery.counter.min.js";
      // scripts[3] = gol.local.data.jsPath + "jqplugins/jquery.autogrowtextarea.min.js"; 

      require(scripts, function () {
        reviewobj.initAfterFileloading(options);
      });
    },
    initAfterFileloading: function initAfterFileloading(options) {
      //console.log("initAfterFileLoading() called");

      // build main options before element iteration
      opts = $.extend({}, gol.review.init.defaults, options);
      this.calmDown();
      // Support the metadata-plugin
      var o = $.meta ? $.extend({}, opts, $(this).data()) : opts;

      // init rating
      this.rating.init();
      this.qualityometer.init();
      if (o.UserHasIncentiveAlready === false) {
        this.incentiveometer.init();
      }
      this.setHandlers();
      this.favorite.init();

      // init possible campaigns (hide teaser images on rating-rollover)
      //this.campaigns.init();

      // Init Form-Validation
      this.validation.init();

      // init tooltips
      gol.tooltips.init();

      //get Options
      $saveAsDraftHiddenFieldId = $("input[name='" + o.saveAsDraftHiddenFieldId + "']");
      saveAsDraftHiddenField = o.saveAsDraftHiddenFieldId;
      AUTOSAVE_ON = o.autosave;
      if (AUTOSAVE_ON) {
        this.autosave.init();
      }

      //falls ausgeloggt, Counter initialisieren & keep alive
      if (gol.local.data.isValidUser === false) {
        $("#txtReview").counter({
          goal: 1500,
          msg: "Zeichen &uuml;brig"
        });

        // ensure that platform stays online until window is closed
        gol.keepAlive.init();
      }
    },
    setHandlers: function setHandlers() {
      $("#saveAsDraftButton").on("click", function () {
        gol.trackEventCat("writeReview", "saveAsDraft");
        addSaveAsDraftElement(true);
      });
      $("#btnSubmitReview").on("click", function () {
        gol.trackEventCat("writeReview", "submitReview");
        addSaveAsDraftElement(false);
      });
      $(".ui-dialog-titlebar-close").on("click", function () {
        gol.trackEventCat("writeReview", "cancel");
        if (AUTOSAVE_ON) {
          $.fn.glAutoSave.cancel($("#txtReview"));
        }
      });
      function addSaveAsDraftElement(booleanValue) {
        // saveAsDraft hinzufügen
        if ($("input[name='" + saveAsDraftHiddenField + "']").length === 0) {
          var input = $("<input>").attr("type", "hidden").attr("name", saveAsDraftHiddenField).val(booleanValue);
          $("#frm_review").append($(input));
        } else {
          $("input[name='" + saveAsDraftHiddenField + "']").replaceWith("<input type='hidden' name='" + saveAsDraftHiddenField + "' value='" + booleanValue + "' />");
        }
      }
    },
    incentiveometer: {
      init: function init() {
        /** @const */var $incentive = $("#incentiveComply");
        var textlength = $("#txtReview").val().length;

        // incentiveometer: ab 250 grün

        $incentive.show();
        if (textlength >= 250) {
          $incentive.html("&#10003; &nbsp;Bewertung qualifiziert sich (nach Länge) für" + "<a href=\"https://blog.golocal.de/10-bewertungen-golocal-sagt-danke-mit-5-euro-amazon-de-gutschein/\" title=\"Erklärungen zur Gutschein-Aktion anzeigen\" target=\"_blank\" rel=\"nofollow\">Gutschein</a>" + ", zusätz. Anford. siehe &ldquo;Hilfe&rdquo;");
        } else {
          $incentive.html("Noch NICHT für <a href=\"https://blog.golocal.de/10-bewertungen-golocal-sagt-danke-mit-5-euro-amazon-de-gutschein/\"" + "title=\"Erklärungen zur Gutschein-Aktion anzeigen\" target=\"_blank\" rel=\"nofollow\">Gutschein-Aktion</a> qualifiziert");
        }
        this.setHandlers();
      },
      setHandlers: function setHandlers() {
        /** @const */var $incentive = $("#incentiveComply");
        $("#txtReview").on("change keyup input", function () {
          var textlength = $("#txtReview").val().length;
          if (textlength < 250) {
            $incentive.html("Noch NICHT für <a href=\"https://blog.golocal.de/10-bewertungen-golocal-sagt-danke-mit-5-euro-amazon-de-gutschein/\"" + "title=\"Erklärungen zur Gutschein-Aktion anzeigen\" target=\"_blank\" rel=\"nofollow\">Gutschein-Aktion</a> qualifiziert");
          }
          if (textlength >= 250) {
            $incentive.html("&#10003; &nbsp;Bewertung qualifiziert sich (nach Länge) für " + "<a href=\"https://blog.golocal.de/10-bewertungen-golocal-sagt-danke-mit-5-euro-amazon-de-gutschein/\" title=\"Erklärungen zur Gutschein-Aktion anzeigen\" target=\"_blank\" rel=\"nofollow\">" + "Gutschein</a>, zusätz. Anford. siehe \&ldquo;Hilfe&rdquo;");
          }
        });
      }
    },
    qualityometer: {
      init: function init() {
        // Qualityometer: bis 250 rot, bis 400 gelb, ab 550 grüner Daumen

        if ($("#txtReview").val().length) {
          $("#quality").css("opacity", "1");
        }
        this.setGreenThumb($("#txtReview").val().length);
        this.setHandlers();
        $("#qualityometer").progressbar({
          value: $("#txtReview").val().length,
          max: 550,
          width: 63,
          height: 11
        });
      },
      setGreenThumb: function setGreenThumb(textlength) {
        /** @const */var $quality = $("#quality");
        /** @const */
        var $saveAsDraftButton = $("#saveAsDraftButton");
        if (textlength === 0) {
          $quality.fadeTo(1000, 0.4);
          $saveAsDraftButton.fadeTo(1000, 0.2);
        }
        if (textlength >= 1 && $quality.css("opacity") !== "1") {
          $quality.fadeTo(1000, 1);
          $saveAsDraftButton.fadeTo(1000, 1.0);
        }

        // grüner Motivations-Daumen, der am Ende der Progressbar leuchtet
        if (textlength >= 550) {
          $("#qualityometerTop").addClass("green");
        } else {
          $("#qualityometerTop").removeClass("green");
        }
      },
      setHandlers: function setHandlers() {
        $("#txtReview").on("change keyup input", function () {
          var textlength = $("#txtReview").val().length;
          gol.review.qualityometer.setGreenThumb(textlength);
          $("#qualityometer").progressbar({
            value: textlength
          });
        });
      }
    },
    validation: {
      init: function init() {
        var urlParams = new URLSearchParams(window.location.search);
        if (urlParams.get('isFavorite') === "true") {
          gol.review.favorite.addFavorite();
        } else {
          var starRating = urlParams.get('starRating');
          var starElement = $('.star-rating a').eq(starRating - 1);
          if (starElement.length) {
            starElement.click();
          }
        }

        /** @const */
        var starsmessage = "Bitte klicke auf einen der 5 Sterne - 1 f&uuml;r schlecht und 5 f&uuml;r super.";
        /** @const */
        var tagsmessage = "<strong>Zu Punkt 2:</strong> Bitte w&auml;hle <strong>mindestens ein</strong> passendes Stichwort.";
        /** @const */
        var reviewmessage = "Bitte teile uns noch in ein paar S&auml;tzen Deine Erfahrungen dort mit.";

        /** @const */
        var $saveAsDraftButton = $("#saveAsDraftButton"),
          /** @const */$submitReviewButton = $("#btnSubmitReview"),
          /** @const */$savedIndicator = $("#savedIndicator");

        // Entwurf speichern Button volle Opacity, wenn Entwurf bearbeiten
        if ($("#txtReview").val().length > 0) {
          $saveAsDraftButton.css("opacity", "1");
        }

        // disabled entfernen, falls User Browser-Back nutzen...
        $submitReviewButton.prop("disabled", false);
        $("#frm_review").validate({
          submitHandler: function submitHandler(form) {
            // Take care of seeing the errorbox after submit

            $("#quality").fadeOut();
            $("#cancellink").fadeOut();
            $saveAsDraftButton.fadeOut();
            $("body").css("cursor", "progress");
            if ($saveAsDraftHiddenFieldId.size() > 0 && $saveAsDraftHiddenFieldId.val() === true) {
              gol.buttonloader.show($saveAsDraftButton);
              //$(".review-buttonsWrap-cancel").fadeOut();
              $submitReviewButton.fadeOut();
              $saveAsDraftButton.prop("disabled", true).css("cursor", "default");
            } else {
              gol.buttonloader.show($submitReviewButton);
              //$(".review-buttonsWrap-cancel").fadeOut();
              $submitReviewButton.prop("disabled", true).css("cursor", "default");
            }

            // Falls Aktion zu lange dauert Bitte warten einblenden
            setTimeout(function () {
              $savedIndicator.append("Bitte warten ...").css("text-decoration", "blink");
            }, 2500);
            form.submit();
          },
          ignore: ".ignore",
          messages: {
            stars: starsmessage,
            tags: tagsmessage,
            review: reviewmessage
          },
          rules: {
            // simple rule, converted to {required:true}
            stars: "required",
            tags: false,
            review: "required"
          },
          onfocusout: false,
          errorPlacement: function errorPlacement(error, element) {
            if ($(element).is(".star-rating-applied")) {
              $(error).insertBefore($("#star"));
            } else {
              $(error).insertBefore(element);
            }
          },
          highlight: function highlight(element, errorClass) {
            if ($(element).is(".star-rating-applied")) {
              $(element).addClass("errorStars"); // Dummy Klasse hinzufügen, um in Callback Funktion von Rating Plugin zu testen, ob man revalidieren muss
            } else {
              $(element).addClass(errorClass).fadeOut(function () {
                $(element).fadeIn();
              });
            }
          },
          errorContainer: $("#ReviewErrorMessageBox"),
          errorLabelContainer: $("#ReviewErrorMessageBox ul"),
          errorClass: "invalid",
          wrapper: "li"
        });
      }
    },
    reloadPage: function reloadPage() {
      // check for ajax-loading and ensure the right page to load
      var reloadLocation = $("#reloadUrl").text();
      gol.gen.reload(reloadLocation);
    },
    autosave: {
      init: function init() {
        /**********************************************/
        /***               AutoSave                 ***/
        /***   (only for users that are logged in   ***/
        /***            and for drafts)             ***/
        //console.log("Initializing autosave");
        var ao = {
          time: 60000,
          savedIndicatorId: "savedIndicator",
          saveAsDraftInputFieldId: "saveAsDraft",
          tokenWrapId: "tokenWrap"
        };
        $("#frm_review").glAutoSave(ao);
      }
    },
    rating: {
      init: function init() {
        var $ratingTexts = $("#review_ratingTexts");
        var $radioinputs = $("#star input[type=radio]");
        $ratingTexts[0].data = $ratingTexts.text();

        // Init rating
        $radioinputs.rating({
          focus: function focus(value, link) {
            $ratingTexts.html(link.title || "value: " + value);
            gol.review.favorite.setInactive();

            //gol.review.campaigns.hideTeaser();
          },
          blur: function blur() {
            $ratingTexts.html($ratingTexts[0].data);
            gol.review.favorite.checkActiveState();

            //gol.review.campaigns.showTeaser();
          },
          callback: function callback() {
            // Wert des geklickten Optionsfeldes speichern
            $ratingTexts[0].data = $("#star input[type=radio]:checked").attr("title");

            // Revalidate, only falls schon Fehlermeldung vorhanden mittels Pseudoklasse
            if ($("#star input[type=radio]").hasClass("errorStars")) {
              $("#frm_review").valid();
            }
          },
          required: "hide" // disable cancel button
        });
      }
    },
    favorite: {
      init: function init() {
        /***************************************/
        /***           Favorite              ***/

        /** @const */$chkFavorite = $("#review_chkFavorite");
        /** @const */
        $btnFavorite = $("#review_favoriteBtn");
        /** @const */
        $ratingInputs = $("#star input[type=radio]");
        /** @const */
        $ratingTexts = $("#review_ratingTexts");
        $aiRatingChkFavorite = $("[data-survey-suggestion-fav]"); // additional favorite input for ai review suggestion

        this.setHandlers();
      },
      addFavorite: function addFavorite() {
        //console.log("add Fav!");
        $chkFavorite.prop("checked", true);
        if ($aiRatingChkFavorite.length > 0) {
          $aiRatingChkFavorite.prop("checked", true); // for ai review
        }
        this.setActive();
        $ratingInputs.rating("select", 4);
        $ratingTexts[0].data = "Einer meiner Favoriten!";
        $ratingTexts.html($ratingTexts[0].data);
      },
      removeFavorite: function removeFavorite() {
        //console.log("remove Fav!");
        $chkFavorite.prop("checked", false);
        if ($aiRatingChkFavorite.length > 0) {
          $aiRatingChkFavorite.prop("checked", false); // for ai review
        }
        //$("#star input[type=radio]").rating("drain");
        $btnFavorite.removeClass("fav_active");

        // set rating text of last clicked rating
        $ratingTexts.html($("#star input[type=radio]:checked").attr("title"));
      },
      setActive: function setActive() {
        $btnFavorite.addClass("fav_active");
      },
      setInactive: function setInactive() {
        $btnFavorite.removeClass("fav_active");
      },
      checkActiveState: function checkActiveState() {
        if ($ratingTexts[0].data === "Einer meiner Favoriten!") {
          this.setActive();
        }
      },
      setHandlers: function setHandlers() {
        $("#star .star-rating").on("click", function () {
          gol.review.favorite.removeFavorite();
        });
        $btnFavorite.on("click", function () {
          if ($("#review_chkFavorite:checked").length > 0) {
            //console.log("checkbox an --> schalte Favorit AUS");
            gol.review.favorite.removeFavorite();
          } else {
            //console.log("checkbox aus --> schalte Favorit AN");
            gol.review.favorite.addFavorite();
          }
        }).on("mouseenter", function () {
          //console.log("favorite hover");
          $ratingTexts.text("Einer meiner Favoriten!");
          $("#star div.star-rating").rating("fill");
        }).on("mouseleave", function () {
          $ratingTexts.text($ratingTexts[0].data);
          $("#star div.star-rating").rating("draw");
        });
      }
    },
    calmDown: function calmDown() {
      var instRev = document.getElementById("txtReview");
      function positionTooltip() {
        var tooltip = document.getElementById("calmDown");
        if (tooltip) {
          if (window.matchMedia("(max-width: 1180px)").matches) {
            tooltip.style.position = "relative";
            tooltip.style.left = "0";
            tooltip.style.top = "0";
            tooltip.style.margin = "10px 0 0 0";
            tooltip.style.width = "100%";
          } else {
            tooltip.style.position = "absolute";
            tooltip.style.zIndex = "1000";
            tooltip.style.left = "100%";
            tooltip.style.top = "0";
            tooltip.style.margin = "0 0 0 10px";
            tooltip.style.width = "300px";
          }
        }
      }
      window.addEventListener("resize", function () {
        positionTooltip();
      }, true);
      function showTooltip() {
        var form = document.getElementById("frm_review");
        var tooltip = document.getElementById("calmDown");
        if (tooltip) {
          setTimeout(function () {
            tooltip.style.display = "block";
            $(tooltip).fadeTo("slow", 1);
            //console.log("fading in @ showing again");
          }, 3000);
        } else {
          $(form).append("" + "<div id='calmDown' class='standardShadow'>" + "<p><strong>Bleib bitte „oberhalb“ der Gürtellinie!</strong></p>" + "<p>Gib nur Deine eigene Meinung wieder, kein Hörensagen. Benutze Formulierungen wie „meiner Meinung nach“, „bei mir“ oder „ich finde“.</p>" + "<p><strong>Beachte unbedingt Folgendes für Deine Bewertung:</strong></p>" + "<ul style='list-style-type:square; list-style-position: inside;'>" + "<li>Keine Verallgemeinerungen</li>" + "<li>Keine Erfahrungen als Arbeitnehmer/Mitarbeiter</li>" + "<li>Keine Unterstellung von Straftaten (z.B. „Betrug“, „Abzocke“ usw.)</li>" + "<li>Keine Schmähkritik (z.B. „inkompetent“, „unseriös“ usw.)</li>" + "<li>Keine Beleidigungen, Diskriminierungen usw.</li>" + "<li>Keine Klarnamen</li>" + "</ul>" + "<br />" + "<a target='_blank' href='https://www.golocal.de/hilfe/#netiquette'>Weitere Informationen…</a>" + "</div>");
          tooltip = document.getElementById("calmDown");
          tooltip.style.padding = "6px 12px";
          tooltip.style.background = "#f9f9f9";
          tooltip.style.border = "1px solid #c63326";
          tooltip.style.fontSize = "100%";
          tooltip.style.opacity = "0";
          tooltip.style.display = "none";
          positionTooltip();
          setTimeout(function () {
            //console.log("fading in @ creating");
            tooltip = document.getElementById("calmDown");
            tooltip.style.display = "block";
            $(tooltip).fadeTo("slow", 1);
          }, 3000);
        }
      }
      function hideTooltip() {
        var tooltip = document.getElementById("calmDown");
        if (tooltip) {
          //tooltip.style.display = "none";
          $(tooltip).fadeTo("slow", 0, function () {
            this.style.display = "none";
          });
          //console.log("fading out");
        }
      }
      window.onload = function () {
        var stars = $(".star-rating a");
        //for (var i = 0; i < 2; i++) {
        var noteZero = stars[0];
        var noteOne = stars[1];
        $(noteZero).on("click", function () {
          if (document.getElementById("txtReview").value !== "") {
            showTooltip();
          }
        });
        $(noteOne).on("click", function () {
          if (document.getElementById("txtReview").value !== "") {
            showTooltip();
          }
        });
        //}
      };
      instRev.addEventListener("focus", function () {
        var selectedNote = document.querySelector("input.star-rating-applied:checked");
        if (selectedNote) {
          if (selectedNote.value < 3) {
            showTooltip();
          } else {
            hideTooltip();
          }
        }
      });
      instRev.addEventListener("blur", function () {
        hideTooltip();
      });
    }
  };
  gol.review.init.defaults = {
    saveAsDraftHiddenFieldId: "saveAsDraft",
    autosave: false,
    UserHasIncentiveAlready: false
  };
})(jQuery);